import React from "react"
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help } from "schema"
import styles from "../styles.scss"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HelpTooltip: React.FC<{
    help: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null
    handleAreaOverFlowChange: (arg: boolean) => void
}> = (props) => {
    const handleMouseOver = () => props.handleAreaOverFlowChange(false)

    const handleMouseLeave = () => {
        setTimeout(() => {
        props.handleAreaOverFlowChange(true)
        }, 1000)
    }

    return (
        <div
        className={styles.tooltipContainer}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        >
        <div className={styles.tooltip}>
            <p dangerouslySetInnerHTML={{ __html: props.help?.hoverHtml ?? "" }} />
        </div>

        <FontAwesomeIcon icon={faQuestionCircle} className={styles.icon} />
    </div>
)}

export default HelpTooltip
