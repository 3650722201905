import React from 'react';
import styles from "./styles.scss"
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help } from 'schema';
import cx from "classnames"
import HelpTooltip from './HelpTooltip';
import HelpInfo from './HelpInfo';

interface IProps {
    help: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null
    isHoverActive?: boolean
    isFEuser?: boolean
    handleAreaOverFlowChange?: (arg: boolean) => void
}

const Help: React.FC<IProps> = (props) => {
    if (props.help === null) {
        return null;
    }
    return (
        <div className={cx(styles.helpContainer, {
            [styles.isFEuser]: props.isFEuser,
        })}>
            {props.isHoverActive ? (
                <HelpTooltip help={props.help} handleAreaOverFlowChange={props.handleAreaOverFlowChange!} />
            ) : (
                <HelpInfo help={props.help} />
                )}
        </div>
    );
};

export default Help;